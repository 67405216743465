import * as React from "react";
import Layout from "../components/layout";
import * as styles from "./confirmation.module.css";
import img from "../images/confirmation.gif";

const ConfirmationPage = () => {
  return (
    <Layout>
        <div className={styles.container}>
            <div className={styles.header}>
              Thanks for your RSVP!
              <br />
              We can't wait to see you :-)
            </div>
            <div>
                <img alt="Dancing figures gif" src={img} className={styles.gif} />
            </div>
        </div>
    </Layout>
  );
};

export default ConfirmationPage

export const Head = () => <title>Bebe and Charlie</title>
